<template>
  <div>
    <footer>
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-4 col-4 mx-auto">
          <div class="JMCbqu">
            ศูนย์ช่วยเหลือ
          </div>
          <ul class="SOb2n7">
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span class="xTjlXx">Help
                Centre</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >สั่งซื้อสินค้าอย่างไร</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href="https://p2cargo.com/manual/profile"
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span class="xTjlXx">เริ่มขายสินค้าอย่างไร</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >ช่องทางการชำระเงินใน p2cargo</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span class="xTjlXx">p2cargo
                Coins</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >การจัดส่งสินค้า</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >การคืนเงินและคืนสินค้า</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >การันตีโดย p2cargo คืออะไร?</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span class="xTjlXx">ติดต่อ
                p2cargo</span></a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4 mx-auto">
          <div class="JMCbqu">
            เกี่ยวกับ p2cargo
          </div>
          <ul class="SOb2n7">
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >เกี่ยวกับเรา</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span class="xTjlXx">โปรแกรม
                Affiliate</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >ร่วมงานกับเรา</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >นโยบายของ p2cargo</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >นโยบายความเป็นส่วนตัว</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span class="xTjlXx">p2cargo
                Blog</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span class="xTjlXx">p2cargo
                Mall</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span class="xTjlXx">Seller
                Centre</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span class="xTjlXx">Flash
                Deals</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><span
                class="xTjlXx"
              >ผู้ติดต่อออนไลน์</span></a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 px-4 mx-auto">
          <div class="JMCbqu">
            วิธีการชำระเงิน
          </div>
          <ul class="D7qxb9">
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/9263fa8c83628f5deff55e2a90758b06"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/c21d34c0a31c00441daf05a97f96df38"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/af82ae9d9ee79d1a064d6496896440ca"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/080424831b3a3dbe82af85852f675831"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/90d3cf6f82d3a1a74c84ab5f876583cb"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/6e7e289bab75472a86d03bb11e2c9baa"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/8335a80af33fb390d580cb3c57ef4330"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/0af0045814cb152488cfcd6921e0ab64"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/734b42470f38e756615738cd5111d139"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/c04844650bc89dac624eab8026caa276"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/8c421b69876d8da18d39213c533408da"
                alt="logo"
              ></a>
            </li>
          </ul>
          <div class="JMCbqu wTATIi">
            บริการจัดส่ง
          </div>
          <ul class="D7qxb9">
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/294478913f17442882513bb8833f1c7a"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/73129aa780add391efbef64246720ba5"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/8c07eb5612750efd0d2e7bfb34a09099"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/ab1e8cd2988eecf57ea4bb44b48dc1dd"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/a136bea64c68258a5a0c8263e4f8104f"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/ee847678378381b73678fd01dc0dc3b3"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/09442b3b2b135f8b6509eb3f67ef9f1c"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/0645ba3892dea64a8538d4604bb690cb"
                alt="logo"
              ></a>
            </li>
            <li class="uqMJQA">
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="_2pbE-b"
              ><img
                src="https://down-th.img.susercontent.com/file/aad738986b9836a17d8705906c36069d"
                alt="logo"
              ></a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-3 col-4 mx-auto">
          <div class="JMCbqu">
            ติดตามเรา
          </div>
          <ul class="SOb2n7">
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><img
                class="_6ZyW8Y"
                src="https://down-th.img.susercontent.com/file/fa8fb1ea9a769cb0343d38ed2826fd1e"
              ><span
                class="xTjlXx"
              >Facebook</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><img
                class="_6ZyW8Y"
                src="https://down-th.img.susercontent.com/file/b1a26fd8cab0c23749f53432c1f59fe6"
              ><span
                class="xTjlXx"
              >Instagram</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><img
                class="_6ZyW8Y"
                src="https://down-th.img.susercontent.com/file/535e22ccc23a8db15d83215261a8b2dd"
              ><span
                class="xTjlXx"
              >Line</span></a>
            </li>
            <li class="o8Gbgv">
              <a
                href=""
                class="FA0WjS"
                title=""
                target="_blank"
                rel="noopener noreferrer"
              ><img
                class="_6ZyW8Y"
                src="https://down-th.img.susercontent.com/file/a2b07c5ed72399ad3fbf4696e0b5861a"
              ><span
                class="xTjlXx"
              >LinkedIn</span></a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-3 col-4 mx-auto">
          <div class="JMCbqu">
            ดาวน์โหลดแอปพลิเคชั่น
          </div>
          <div class="pkg67p">
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
            ><img
              src="/qr.webp"
              alt="download_qr_code"
              class="ebQ6br"
              height="100"
            ></a>
            <div class="zLPzwH">
              <a
                href="https://linkedin.com/company/anan"
                target="_blank"
                rel="noopener noreferrer"
                class="W4jGm6"
              ><img
                src="https://down-th.img.susercontent.com/file/ed6fcd311cab7abf948d7cd81929c53e"
                alt="app"
              ></a><a
                href="https://linkedin.com/company/anan"
                target="_blank"
                rel="noopener noreferrer"
                class="W4jGm6"
              ><img
                src="https://down-th.img.susercontent.com/file/6be5f60baa027fff58b1b16d44e28a66"
                alt="app"
              ></a><a
                href="https://linkedin.com/company/anan"
                target="_blank"
                rel="noopener noreferrer"
                class="W4jGm6"
              ><img
                src="https://down-th.img.susercontent.com/file/038f533c8d76c728fb64c57ab00a12d6"
                alt="app"
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-details">
        <div class="bottom_text">
          <span class="copyright_text">Copyright © 2021 <a href="#">P2 Cargo.</a>All rights reserved</span>
          <span class="policy_terms">
            <a href="#">Privacy policy</a>
            <a href="#">Terms & condition</a>
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>
